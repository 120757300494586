#root {
  /* background: rgb(6, 16, 26);
  background-image: fixed; */
  height: 100%;
  color: white;
  background-color: #130f40;
  background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
}

.hiThere {
  opacity: 0;
  transition: 2s opacity;
}

.fadeIn {
  opacity: 1;
}

#nameInput,
#messageInput,
#emailInput {
  color: white;
}

body::-webkit-scrollbar {
  width: 0.75em;
}

body::-webkit-scrollbar-track {
  background-color: rgb(6, 16, 26);
  width: 0.75em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(156, 39, 176);
  border-radius: 1em;
}